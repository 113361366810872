import { FilterDataType } from "src/enums/TagType";
import { RotationNote, ShiftNote } from "src/models/NoteItem";
import { Report } from "src/models/Report";
import { FilterState } from "src/store/reducers/filterReducer";
import { HistoryState } from "src/store/reducers/historyReducer";
import { APIService } from "./api.service";
import { RoleFilterItem } from "src/models/FilterItem";
import { DateTimeHelper } from "src/helpers/DateTimeHelper";

export default class ReportService extends APIService {
    private url = '/reports';

    allowedCategoryTags = ["environmental", "safety"];

    pageTitle = "Shift Turnover (Draft Report)";
    noShiftMessage = "No Shift found";
    noNotesMessage = "No Notes found";
    noRotationMessage = "No Rotation found";

    contStDef = "d-flex-column gap-3";
    titleStDef = "d-flex gotham-bold medium p-2";
    priNotesTitleStRef = this.titleStDef + " justify-content-center bg-lighter-";
    priNotesTitleStDef = this.titleStDef + " justify-content-center bg-lighter-red";
    catNotesTitleStDef = this.titleStDef + " justify-content-center bg-lighter-blue";
    shiftNotesTitleStDef = this.titleStDef + " justify-content-center bg-lighter-gray";
    rotationNotesTitleStDef = this.titleStDef + " justify-content-center bg-lighter-gray";

    getHistoryPageTitle(isRotation: boolean) {
        return `History ${isRotation ? 'Rotation' : 'Shift'} Notes ${this.pageTitle}`
    }

    getDateTimeString = (date: Date) => {
        const dateVal = new Date(date);

        const dayName = dateVal.toLocaleString('en-us', { weekday: 'short' });

        return `${dayName}, ${dateVal.toLocaleDateString()} ${dateVal.toLocaleTimeString()}`;
    }

    getSelectedRolesTitle = (roleFilterItems: RoleFilterItem[]) => {
        return roleFilterItems && roleFilterItems.length > 0 ? roleFilterItems.map(r => r.name).join(', ') : 'All';
    }

    getFilterDataMarkup = (filterData: FilterState, historyData: HistoryState, filterDataType: FilterDataType) => {
        switch (filterDataType) {
            case FilterDataType.Role:
                return filterData.allFilters.selectedRoleIds?.length > 0 ?
                    filterData.allFilters.selectedRoleIds.map(r => r.substring(0, 2)).join(", ") :
                    "All";

            case FilterDataType.CategoryTag:
                return filterData.allFilters.categoryTags.filter(t => t.isSelected)?.length > 0 ?
                    filterData.allFilters.categoryTags.filter(t => t.isSelected).map(t => t.name).join(", ") :
                    "All";

            case FilterDataType.RoleTag: //TODO: CHECK THIS NEEDS TO SHOW CONTACTENATED NAMES
                return filterData.allFilters.roleTags.flatMap(rt => rt.tags.filter(t => t.isSelected)).length > 0
                    ? filterData.allFilters.roleTags.flatMap(rt => rt.tags.filter(t => t.isSelected).map(t => t.name)).join(", ")
                    : "All";


            case FilterDataType.ShiftSchedulePeriod:
                return filterData.allFilters.shiftSchedulePeriods?.length > 0 ?
                    filterData.allFilters.shiftSchedulePeriods.filter(s => s.isSelected)?.map(s => s.name).join(", ") :
                    "All";

            case FilterDataType.Location:
                return filterData.allFilters.selectedLocationIds?.length > 0 ?
                    filterData.allFilters.locations.filter(l => filterData.allFilters.selectedLocationIds.includes(l.id)).map(l => l.name).join(", ") :
                    "All";

            case FilterDataType.DateRange:
                {
                    {/* TODO: to change selectedHistoryDates from array to object in Redux */ }
                    let dateVal = '';

                    if (historyData.selectedHistoryDates)
                        dateVal = dateVal.concat(this.getDateTimeString(historyData.selectedHistoryDates[0]));

                    if (historyData.selectedHistoryDates[1])
                        dateVal = dateVal.concat(" - ", this.getDateTimeString(historyData.selectedHistoryDates[1]));

                    return dateVal;
                }

            case FilterDataType.Text:
                return historyData.selectedHistoryText;

            default:
                break;
        }
    }

    async filterShiftNoteByPriority(shiftNote: ShiftNote) {
        if (shiftNote.locationNotesList) {
            shiftNote.locationNotesList.filter(ln => {
                ln.notes = ln.notes.filter((n) => n.isCritical);

                return ln;
            });

            shiftNote.locationNotesList = shiftNote.locationNotesList.filter(ln => ln.notes.length > 0);
        }
    }

    async filterShiftNotesByPriority(shiftNotes: any[]) {
        shiftNotes.filter(sn => this.filterShiftNoteByPriority(sn));
    }

    async filterShiftNoteByCategoryTags(shiftNote: any) {
        if (shiftNote.locationNotesList) {
            shiftNote.locationNotesList.filter(ln => {
                ln.notes = ln.notes.filter((n) => !n.isCritical && n.tags.some(t => this.allowedCategoryTags.includes(t.name.toLocaleLowerCase())));

                return ln;
            }).filter(ln => ln.notes.length > 0);

            shiftNote.locationNotesList = shiftNote.locationNotesList.filter(ln => ln.notes.length > 0);
        }
    }

    async filterShiftNoteBySectionTags(shiftNote: any, tagName: string) {
        tagName = tagName.toLocaleLowerCase();

        if (shiftNote.locationNotesList) {
            shiftNote.locationNotesList.filter(ln => {
                ln.notes = ln.notes.filter((n) => n.tags.some(t => tagName === (t.name.toLocaleLowerCase())));

                return ln;
            }).filter(ln => ln.notes.length > 0);

            shiftNote.locationNotesList = shiftNote.locationNotesList.filter(ln => ln.notes.length > 0);
        }
    }

    async filterShiftNoteExcludeSectionTags(shiftNote: any, tagNames: string[]) {
        tagNames = tagNames.map(function (v) {
            return v.toLowerCase();
        });

        if (shiftNote.locationNotesList) {
            shiftNote.locationNotesList.filter(ln => {
                ln.notes = ln.notes.filter((n) => !n.tags.some(t => tagNames.includes(t.name.toLocaleLowerCase())));

                return ln;
            }).filter(ln => ln.notes.length > 0);

            shiftNote.locationNotesList = shiftNote.locationNotesList.filter(ln => ln.notes.length > 0);
        }
    }

    async filterRotationNoteBySectionTags(rotationNote: any, tagName: string) {
        tagName = tagName.toLocaleLowerCase();

        if (rotationNote?.rotationNotes) {
            rotationNote.rotationNotes.filter(ln => {
                ln.notes = ln.notes.filter((n) => n.tags.some(t => tagName.includes(t.name.toLocaleLowerCase())));

                return ln;
            }).filter(ln => ln.notes.length > 0);

            rotationNote.rotationNotes = rotationNote.rotationNotes.filter(ln => ln.notes.length > 0);
        }
    }

    async filterRotationNoteExcludeSectionTags(rotationNote: any, tagNames: string[]) {
        tagNames = tagNames.map(function (v) {
            return v.toLowerCase();
        });

        if (rotationNote?.rotationNotes) {
            rotationNote.rotationNotes.filter(ln => {
                ln.notes = ln.notes.filter((n) => !n.tags.some(t => tagNames.includes(t.name.toLocaleLowerCase())));

                return ln;
            }).filter(ln => ln.notes.length > 0);

            rotationNote.rotationNotes = rotationNote.rotationNotes.filter(ln => ln.notes.length > 0);
        }
    }

    async filterShiftNotesByCategoryTags(shiftNotes: any[]) {
        shiftNotes.filter(sn => this.filterShiftNoteByCategoryTags(sn));
    }

    async filterShiftNote(shiftNote: any) {
        if (shiftNote.locationNotesList) {
            shiftNote.locationNotesList.filter(ln => {
                ln.notes = ln.notes.filter((n) => !n.isCritical);
                ln.notes = ln.notes.filter((n) => !n.tags.some(t => this.allowedCategoryTags.includes(t.name.toLocaleLowerCase())));

                return ln;
            }).filter(ln => ln.notes.length > 0);

            shiftNote.locationNotesList = shiftNote.locationNotesList.filter(ln => ln.notes.length > 0);
        }
    }

    async filterShiftNotes(shiftNotes: any[]) {
        shiftNotes.filter(sn => this.filterShiftNote(sn));
    }

    // Rotation Notes
    async filterRotationNoteByCategoryTags(rotationNote: RotationNote) {
        if (rotationNote.rotationNotes) {
            rotationNote.rotationNotes.filter(rn => {
                rn.notes = rn.notes.filter((n) => n.tags.some(t => this.allowedCategoryTags.includes(t.name.toLocaleLowerCase())));

                return rn;
            }).filter(ln => ln.notes.length > 0);

            rotationNote.rotationNotes = rotationNote.rotationNotes.filter(ln => ln.notes.length > 0);
        }
    }

    async filterRotationNotesByCategoryTags(rotationNotes: RotationNote[]) {
        rotationNotes.filter(rn => this.filterRotationNoteByCategoryTags(rn));
    }

    async filterRotationNote(rotationNote: RotationNote) {
        if (rotationNote.rotationNotes) {
            rotationNote.rotationNotes?.filter(rn => {
                rn.notes = rn.notes.filter((n) => !n.tags.some(t => this.allowedCategoryTags.includes(t.name.toLocaleLowerCase())));

                return rn;
            }).filter(ln => ln.notes.length > 0);

            rotationNote.rotationNotes = rotationNote.rotationNotes.filter(ln => ln.notes.length > 0);
        }
    }

    async filterRotationNotes(rotationNotes: RotationNote[]) {
        rotationNotes.filter(rn => this.filterRotationNote(rn));
    }

    async getAllReportsByCurrentUser(): Promise<Report[]> {
        const response = await this.api.get(`${this.url}/by-current-user`);

        let reports: Report[] = [];

        if (response.data && response.data.length > 0) {
            reports = response.data.map((r => { return { ...r, executionTime: DateTimeHelper.fromUTCToLocal(r.executionTime) } }));
        }

        return reports;
    }

    async addReport(report: Report): Promise<Report> {
        const response = await this.api.post(`${this.url}`, report);
        return response.data;
    }

    async updateReport(report: Report): Promise<boolean> {
        const response = await this.api.put(`${this.url}`, report);
        return response.data;
    }

    async duplicateReport(report: Report): Promise<boolean> {
        const response = await this.api.post(`${this.url}/duplicate`, report);
        return response.data;
    }

    async updateReportActive(report: Report): Promise<boolean> {
        const response = await this.api.put(`${this.url}/${report.id}/state/${!report.isActive}`);
        return response.data;
    }

    async deleteReport(report: Report): Promise<boolean> {
        const response = await this.api.delete(`${this.url}`, {
            params: {
                'reportId': report.id
            },
            headers: {
                'accept': 'text/plain'
            }
        });
        return response.data;
    }
}